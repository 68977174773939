
/* ::v-deep .el-table_bode-wrapper{

}
.center {
  overflow: auto;
  overflow-x: hidden;
}
.picListImg {
  width: 180px;
  padding: 5px;
} */
